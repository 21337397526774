import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm$sections$mainSect, _vm$sections$mainSect2, _vm$sections$offerSec, _vm$content$offer, _vm$content, _vm$sections$partnerS, _vm$sections$partnerS2, _vm$content2, _vm$sections$sellOnli, _vm$sections$sellOnli2, _vm$content3, _vm$sections$managerS;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    class: _vm.$style.IndexPage
  }, [_c('div', {
    class: _vm.$style.mainSection
  }, [_c('div', {
    class: _vm.$style.container
  }, [_c('div', {
    class: _vm.$style.inner
  }, [_c('div', {
    class: _vm.$style.left
  }, [_c('h1', {
    class: _vm.$style.visuallyHidden
  }, [_vm._v("\n            Онлайн-сервис для брокеров по продаже недвижимости по всей России\n          ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.title,
    domProps: {
      "innerHTML": _vm._s((_vm$sections$mainSect = _vm.sections.mainSection) === null || _vm$sections$mainSect === void 0 ? void 0 : _vm$sections$mainSect.title)
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.description,
    domProps: {
      "innerHTML": _vm._s((_vm$sections$mainSect2 = _vm.sections.mainSection) === null || _vm$sections$mainSect2 === void 0 ? void 0 : _vm$sections$mainSect2.text)
    }
  }), _vm._v(" "), _c('SButton', {
    class: _vm.$style.button,
    attrs: {
      "n-link": "",
      "href": "/login",
      "rounded": "",
      "theme": "primary",
      "size": "x-large"
    }
  }, [_vm._v("\n            Войти / Зарегистрироваться\n          ")])], 1), _vm._v(" "), _c('div', {
    class: _vm.$style.right
  }, [_c('div', {
    class: _vm.$style.img
  }, [_vm._m(0)])])])])]), _vm._v(" "), _c('div', {
    class: _vm.$style.offerSection
  }, [_c('span', {
    class: [_vm.$style.gradientCircle, _vm.$style.gradientCircle1]
  }), _vm._v(" "), _c('span', {
    class: [_vm.$style.gradientCircle, _vm.$style.gradientCircle2]
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.container
  }, [_c('div', {
    class: _vm.$style.inner
  }, [_c('div', {
    class: [_vm.$style.title, _vm.$style.gradientText],
    domProps: {
      "innerHTML": _vm._s((_vm$sections$offerSec = _vm.sections.offerSection) === null || _vm$sections$offerSec === void 0 ? void 0 : _vm$sections$offerSec.title)
    }
  }), _vm._v(" "), _c('LandingOffersSlider', {
    attrs: {
      "items": (_vm$content$offer = (_vm$content = _vm.content) === null || _vm$content === void 0 ? void 0 : _vm$content.offer) !== null && _vm$content$offer !== void 0 ? _vm$content$offer : []
    }
  })], 1)])]), _vm._v(" "), _c('div', {
    class: _vm.$style.partnersSection
  }, [_c('div', {
    class: _vm.$style.container
  }, [_c('div', {
    class: _vm.$style.inner
  }, [_c('div', {
    class: _vm.$style.count,
    domProps: {
      "innerHTML": _vm._s((_vm$sections$partnerS = _vm.sections.partnerSection) === null || _vm$sections$partnerS === void 0 ? void 0 : _vm$sections$partnerS.title)
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.description,
    domProps: {
      "innerHTML": _vm._s((_vm$sections$partnerS2 = _vm.sections.partnerSection) === null || _vm$sections$partnerS2 === void 0 ? void 0 : _vm$sections$partnerS2.text)
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.bottom
  }, [_c('div', {
    class: _vm.$style.text
  }, [_c('span', [_vm._v("\n              Наши\n            ")]), _vm._v(" "), _c('span', [_vm._v("\n              партнёры\n            ")])]), _vm._v(" "), _c('LandingPartnerSlider', {
    attrs: {
      "items": (_vm$content2 = _vm.content) === null || _vm$content2 === void 0 ? void 0 : _vm$content2.partnerLogo
    }
  })], 1)])])]), _vm._v(" "), _c('div', {
    class: _vm.$style.signUp
  }, [_c('span', {
    class: [_vm.$style.gradientCircle, _vm.$style.gradientCircle3]
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.container
  }, [_c('div', {
    class: _vm.$style.inner
  }, [_c('div', {
    class: _vm.$style.top
  }, [_c('div', {
    class: _vm.$style.left
  }, [_c('div', {
    class: [_vm.$style.title, _vm.$style.gradientText]
  }, [_c('div', [_vm._v("\n                Зарегистрируйтесь\n              ")]), _vm._v(" "), _vm._m(1)]), _vm._v(" "), _c('div', {
    class: [_vm.$style.card, _vm.$style.cardFirst]
  }, [_c('div', {
    class: _vm.$style.cardNumber
  }, [_vm._v("1")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardTitle
  }, [_c('div', [_vm._v("Перейдите")]), _vm._v("\n                по ссылке\n              ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardButton
  }, [_c('SButton', {
    class: _vm.$style.button,
    attrs: {
      "n-link": "",
      "href": "/account/agents/registration",
      "rounded": "",
      "theme": "primary",
      "size": "x-large"
    }
  }, [_vm._v("\n                  Зарегистрироваться\n                ")])], 1)])]), _vm._v(" "), _c('div', {
    class: _vm.$style.right
  }, [_c('div', {
    class: [_vm.$style.card, _vm.$style.cardSecond]
  }, [_c('div', {
    class: _vm.$style.cardNumber
  }, [_vm._v("2")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardTitle
  }, [_vm._v("\n                Пройдите регистрацию\n              ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardDescription
  }, [_vm._v("\n                Зарегистрируйте ваше агентство, если его еще нет в системе\n              ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardImg
  }, [_vm._m(2)])])])]), _vm._v(" "), _c('div', {
    class: _vm.$style.center
  }, [_c('div', {
    class: [_vm.$style.card, _vm.$style.cardThird]
  }, [_c('div', {
    class: _vm.$style.cardNumber
  }, [_vm._v("3")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardTitle
  }, [_vm._v("\n              Зарегистрируйте агента\n            ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardDescription
  }, [_vm._v("\n              Укажите ИНН вашего агентства\n            ")]), _vm._v(" "), _c('div', {
    class: _vm.$style.cardImg
  }, [_vm._m(3)])])])])])]), _vm._v(" "), _c('div', {
    class: _vm.$style.online
  }, [_c('span', {
    class: [_vm.$style.gradientCircle, _vm.$style.gradientCircle2]
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.container
  }, [_c('div', {
    class: _vm.$style.inner
  }, [_c('div', {
    class: _vm.$style.top
  }, [_c('div', {
    class: [_vm.$style.title, _vm.$style.gradientText],
    domProps: {
      "innerHTML": _vm._s((_vm$sections$sellOnli = _vm.sections.sellOnlineSection) === null || _vm$sections$sellOnli === void 0 ? void 0 : _vm$sections$sellOnli.title)
    }
  }), _vm._v(" "), _c('div', {
    class: _vm.$style.text,
    domProps: {
      "innerHTML": _vm._s((_vm$sections$sellOnli2 = _vm.sections.sellOnlineSection) === null || _vm$sections$sellOnli2 === void 0 ? void 0 : _vm$sections$sellOnli2.text)
    }
  })]), _vm._v(" "), _c('div', {
    class: _vm.$style.bottom
  }, [_c('div', {
    class: _vm.$style.left
  }, [_c('div', {
    class: _vm.$style.items
  }, _vm._l((_vm$content3 = _vm.content) === null || _vm$content3 === void 0 ? void 0 : _vm$content3.sellOnline, function (item, index) {
    return _c('div', {
      key: index,
      class: _vm.$style.item
    }, [_c('div', {
      class: _vm.$style.itemTitle
    }, [_vm._v("\n                  " + _vm._s(item.title) + "\n                ")]), _vm._v(" "), _c('div', {
      class: _vm.$style.itemText
    }, [_vm._v("\n                  " + _vm._s(item.description) + "\n                ")])]);
  }), 0), _vm._v(" "), _c('div', {
    class: _vm.$style.buttonWrapper
  }, [_c('SButton', {
    class: _vm.$style.button,
    attrs: {
      "n-link": "",
      "href": "/account/agents/registration",
      "rounded": "",
      "theme": "primary",
      "size": "x-large"
    }
  }, [_vm._v("\n                Зарегистрироваться\n              ")])], 1)]), _vm._v(" "), _c('div', {
    class: _vm.$style.img
  }, [_vm._m(4)])])])])]), _vm._v(" "), _c('div', {
    class: _vm.$style.consultation
  }, [_c('div', {
    class: _vm.$style.container
  }, [_c('ConsultationSection', {
    attrs: {
      "title": (_vm$sections$managerS = _vm.sections.managerSectionTitle) === null || _vm$sections$managerS === void 0 ? void 0 : _vm$sections$managerS.title,
      "template": _vm.sections.managerSection,
      "manager": _vm.manager
    }
  })], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('picture', [_c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/main-img.png"),
      "media": "(max-width: 767px)"
    }
  }), _vm._v(" "), _c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/main-img@x2.png"),
      "media": "(min-width: 768px)"
    }
  }), _vm._v(" "), _c('img', {
    attrs: {
      "src": require("@/assets/images/index/main-img.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', [_c('span', [_vm._v("\n                  в пару кликов\n                ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('picture', [_c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/sign-up-img-1.png"),
      "media": "(max-width: 767px)"
    }
  }), _vm._v(" "), _c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/sign-up-img-1@x2.png"),
      "media": "(min-width: 768px)"
    }
  }), _vm._v(" "), _c('img', {
    attrs: {
      "src": require("@/assets/images/index/sign-up-img-1.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('picture', [_c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/sign-up-img-2.png"),
      "media": "(max-width: 767px)"
    }
  }), _vm._v(" "), _c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/sign-up-img-2@x2.png"),
      "media": "(min-width: 768px)"
    }
  }), _vm._v(" "), _c('img', {
    attrs: {
      "src": require("@/assets/images/index/sign-up-img-2.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('picture', [_c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/online-img.png"),
      "media": "(max-width: 767px)"
    }
  }), _vm._v(" "), _c('source', {
    attrs: {
      "srcset": require("@/assets/images/index/online-img@x2.png"),
      "media": "(min-width: 768px)"
    }
  }), _vm._v(" "), _c('img', {
    attrs: {
      "src": require("@/assets/images/index/online-img.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };